import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import useAmountTransactionService from 'services/useAmountTransactionService';
import { daysInMonth } from 'utils/DateOperations';

const ABMCard = (props) => {
    const { employee, abmSalarySlabDetails, abmFiledExpensesDetails, abmDailyAllowanceDetails, abmPRIsDetails, onRefresh, month, year } =
        props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states

    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(daysInMonth(new Date().getMonth(), new Date().getFullYear()));
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(true);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();
    const [daDays, setDaDays] = useState(workDays);
    const [abmSalaryDetails, SetABMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0,
        //campToCamp: 0,
        //adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedABMSalarySlab, setAppliedABMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0
    });

    const [abmDailyAllowance, setABMDailyAllowance] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        campToCamp: 0
    });
    const [appliedABMDAsSalarySlab, setAppliedABMDAsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        campToCamp: 0
    });

    const [abmPRIsSalaryDetails, setABMPRIsSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        pri: 0
    });
    const [appliedABMPRIsSalarySlab, setAppliedABMPRIsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        pri: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    //calculated values
    const handleCalculate = () => {
        const perDaySalary = abmSalarySlabDetails?.fixedSalary / daysInMonth(month, year);
        // Calculate the basic salary based on the number of working days
        const basicSalary = perDaySalary * workDays;

        let calcAmount = appliedABMSalarySlab.fieldExpenses * unitsSold;
        let calcPetrol = appliedABMSalarySlab.petrol;
        let calcIncentive = appliedABMSalarySlab.incentive;
        let calcPRIAmount = appliedABMPRIsSalarySlab.pri;
        // let calcDA = appliedGCSalarySlab.da * workDays;
        // let calcTA = appliedGCSalarySlab.ta * workDays;
        // let calcPetrol = 0;
        // if (employee.haveVehicle && unitsSold > 25) {
        //     let liters = (unitsSold / 2) * 1.5;
        //     calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        // } else {
        //     calcPetrol = 0;
        // }

        //let calcDAsAmount = appliedABMDAsSalarySlab.amount;
        let calcDAsAmount = appliedABMDAsSalarySlab.amount * daDays;
        let calcCampToCamp = appliedABMDAsSalarySlab.campToCamp;
        let calcSubTotal = basicSalary + calcAmount + calcPetrol + calcDAsAmount + calcCampToCamp + calcPRIAmount + calcIncentive;
        let calcDeduction = pendingAmountDetails?.amount || 0;
        let calcNetAmount = calcSubTotal - calcDeduction;

        SetABMSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: basicSalary,
            units: unitsSold,
            fieldExpenses: calcAmount,

            petrol: calcPetrol,
            incentive: calcIncentive,
            subTotal: calcSubTotal,
            deduction: calcDeduction,
            //adjustment: adjustment,
            netAmount: calcNetAmount
        }));
        setABMDailyAllowance((prev) => ({
            ...prev,
            fixedSalary: appliedABMDAsSalarySlab.fixedSalary,
            units: unitsSold,
            amount: calcDAsAmount,
            campToCamp: calcCampToCamp
            //netAmount: calcNetAmount
        }));
        setABMPRIsSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: appliedABMPRIsSalarySlab.fixedSalary,
            units: unitsSold,
            pri: calcPRIAmount

            //netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(abmFiledExpensesDetails);
        let selectedData = abmFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedDAsData = abmDailyAllowanceDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        let selectedPRIsData = abmPRIsDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });
        //console.log(selectedDAsData);

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                petrol: selectedData[0].petrol,
                incentive: selectedData[0].incentive
            };
            setAppliedABMSalarySlab(data);
            handleCalculate();
        }

        if (selectedDAsData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedDAsData[0].unitMin,
                maxUnit: selectedDAsData[0].unitMax,
                amount: selectedDAsData[0].amount,
                campToCamp: selectedDAsData[0].campToCamp
            };
            setAppliedABMDAsSalarySlab(data);
            handleCalculate();
        }
        if (selectedPRIsData.length > 0) {
            let data = {
                fixedSalary: abmSalarySlabDetails.fixedSalary,
                minUnit: selectedPRIsData[0].unitMin,
                maxUnit: selectedPRIsData[0].unitMax,
                pri: selectedPRIsData[0].pri
            };
            setAppliedABMPRIsSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };

    const abmSaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            unitSold: unitsSold,
            workDays: workDays,
            daDays: daDays,
            // adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: abmSalaryDetails.fixedSalary,
            fieldExpences: abmSalaryDetails.fieldExpenses,
            pri: abmPRIsSalaryDetails.pri,
            da: abmDailyAllowance.amount,
            ta: abmDailyAllowance.campToCamp,
            petrol: abmSalaryDetails.petrol,
            incentive: abmSalaryDetails.incentive,
            subTotal: abmSalaryDetails.subTotal,
            deduction: abmSalaryDetails.deduction,
            //groupIncentive: glGroupIncentiveSalary.amount,
            netSalary: abmSalaryDetails.netAmount,
            remark: ''
        };
        console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setSaveGCSalary(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [
        petrolRate,
        workDays,
        unitsSold,
        abmSalarySlabDetails,
        abmFiledExpensesDetails,
        abmDailyAllowanceDetails,
        abmPRIsDetails,
        employee.id
    ]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (abmSalarySlabDetails && abmFiledExpensesDetails && abmDailyAllowanceDetails && abmPRIsDetails) {
            handleSetSalarySlabData();
        }
    }, [abmSalarySlabDetails, abmFiledExpensesDetails, abmDailyAllowanceDetails, abmPRIsDetails, unitsSold]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                {editMode && (
                    <Button
                        size="small"
                        onClick={() => {
                            handleGetPendingAmount();
                            handelGetSalesData();
                            handleCalculate();
                        }}
                    >
                        <RefreshIcon fontSize="small" />
                    </Button>
                )}
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        type="number"
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />
                    <TextField
                        label="DA Days"
                        fullWidth
                        type="number"
                        size="small"
                        value={daDays}
                        onChange={(e) => {
                            setDaDays(e.target.value);
                        }}
                    />

                    <TextField
                        label="Petrol Rate"
                        size="small"
                        type="number"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" spacing={3}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Units Sold
                        </Typography>
                        <Typography>{unitsSold}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Work Days
                        </Typography>
                        <Typography>{workDays}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Petrol Rate
                        </Typography>
                        <Typography>₹ {petrolRate}</Typography>
                    </Stack>
                </Stack>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${abmSalaryDetails.fixedSalary.toFixed(2)} (From Table)`}
                    value={abmSalaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedABMSalarySlab.fieldExpenses} (From Table)`}
                    value={abmSalaryDetails.fieldExpenses}
                />
                <SalaryDetailComponent
                    title="Petrol"
                    description={`₹ ${appliedABMSalarySlab.petrol} (From Table)`}
                    value={abmSalaryDetails.petrol}
                />
                <SalaryDetailComponent
                    title="Incentive"
                    description={`₹ ${appliedABMSalarySlab.incentive} (From Table)`}
                    value={abmSalaryDetails.incentive}
                />
                <SalaryDetailComponent
                    title="DAs"
                    description={`(${daDays} Days) x ₹ ${appliedABMDAsSalarySlab.amount} (From Table)`}
                    value={abmDailyAllowance.amount}
                />
                <SalaryDetailComponent
                    title="Camp To Camp"
                    description={` ₹ ${appliedABMDAsSalarySlab.campToCamp} (From Table)`}
                    value={abmDailyAllowance.campToCamp}
                />

                <SalaryDetailComponent
                    title="PRI"
                    description={`₹ ${appliedABMPRIsSalarySlab.pri} (From Table)`}
                    value={abmPRIsSalaryDetails.pri}
                />

                <Divider />
                <SalaryDetailComponent title="Sub Total" value={abmSalaryDetails.subTotal} />
                <Divider />
                <SalaryDetailComponent
                    title="Deduction"
                    description={` ₹ ${abmSalaryDetails.deduction}`}
                    value={abmSalaryDetails.deduction}
                />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={abmSalaryDetails.netAmount} />
                <Divider />
            </Stack>

            {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={abmSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetABMSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{abmSalaryDetails.remarks == '' ? '---' : abmSalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? ( */}
            <>
                <Button
                    variant="outlined"
                    onClick={() => {
                        handleCalculate();
                    }}
                >
                    Calculate
                </Button>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    onClick={() => {
                        //handleCalculate();
                        abmSaveSalary();
                        //setEditMode(false);
                    }}
                    sx={{ marginLeft: 2 }}
                >
                    Save
                </LoadingButton>
            </>
            {/* ) : ( */}
            {/* <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    Edit
                </Button>
            )} */}
        </Card>
    );
};

export default ABMCard;
