import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

//MaterialUI Imports
import {
    Autocomplete,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import SellIcon from '@mui/icons-material/Sell';

//Third Party Import
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import useSalesRecordService from 'services/useSalesRecordService';
import useEmployeeService from 'services/useEmployeeService';
import { locations } from 'store/constant';
import useLocationService from 'services/useLocationService';

const SalesRecodsForm = (props) => {
    //Constants
    const navigate = useNavigate();
    const { onSave, onCancel, selectedSalesRecord } = props;
    const { addSalesRecord, updateSalesRecord } = useSalesRecordService();
    const { getEmployees } = useEmployeeService();
    const { getLocationList } = useLocationService();

    const { userId } = useSelector((state) => state.userReducer);

    //States
    const [loading, setLoading] = useState();
    const [employee, setEmployee] = useState([]);
    const [location, setLocation] = useState();
    const [currentSkipValue, setCurrentSkipValue] = useState(0);
    const pageSize = 50;

    //methods
    const getEmployeeList = (locationId) => {
        getEmployees('', -1, locationId, currentSkipValue, pageSize)
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    setEmployee(response.data.users);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        handleGetLocation();
    }, []);
    useEffect(() => {
        getEmployeeList();
    }, []);
    useEffect(() => {
        if (location && location.length > 0) {
            formik.setValues({
                ...formik.values,
                locationId: location[0].id // Set the locationId to the id of the first location
            });
        }
    }, [location]);
    const formik = useFormik({
        initialValues: {
            id: selectedSalesRecord.id,
            date:
                selectedSalesRecord.date != undefined || selectedSalesRecord.date != null
                    ? new Date(selectedSalesRecord.date.toString() + 'Z').toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0],
            soldBy: selectedSalesRecord.soldBy,
            recordedBy: userId,
            locationId: selectedSalesRecord.locationId,
            note: selectedSalesRecord.note
        },
        validationSchema: Yup.object({
            //soldBy: Yup.number(),
            //recordedBy: Yup.number().required('Record is required'),
            locationId: Yup.number(),
            note: Yup.string().max(100, 'Note must be 100 characters or less')
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedSalesRecord.id != 0) {
                updateSalesRecord(selectedSalesRecord.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                addSalesRecord(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            if (response.data.newId != undefined) {
                                navigate('/sales-details?Id=' + response.data.newId);
                            }
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    useEffect(() => {
        // Update the employee list when the location changes
        if (formik.values.locationId) {
            getEmployeeList(formik.values.locationId);
        }
    }, [formik.values.locationId]);

    return (
        <>
            <Grid align="center">
                {selectedSalesRecord.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <SellIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Sales Record</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <SellIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Sales Record Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                type="date"
                                name="date"
                                label="Sales date"
                                onChange={formik.handleChange}
                                value={formik.values.date}
                                fullWidth
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        {/* <Grid item sm={6} xs={12}>
                            <Stack spacing={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="employee-select-label">Sold By</InputLabel>
                                    <Select
                                        labelId="employee-select-label"
                                        id="employee-select"
                                        label="Sold By"
                                        name="soldBy"
                                        onChange={formik.handleChange}
                                        value={formik.values.soldBy}
                                        fullWidth
                                        error={formik.touched.soldBy && Boolean(formik.errors.soldBy)}
                                        helperText={formik.touched.soldBy && formik.errors.soldBy}
                                    >
                                        {employee?.map((data, index) => (
                                            <MenuItem key={index} value={data.id}>
                                                {data.firstName} {data.middleName} {data.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid> */}
                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                options={employee || []}
                                getOptionLabel={(data) => `${data.firstName} ${data.middleName} ${data.lastName}`}
                                id="employee-select"
                                name="soldBy"
                                //value={formik.values.soldBy}
                                value={employee[0] || null}
                                onChange={(_, newValue) => {
                                    formik.setFieldValue('soldBy', newValue.id);
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Sold By"
                                        error={formik.touched.soldBy && Boolean(formik.errors.soldBy)}
                                        helperText={formik.touched.soldBy && formik.errors.soldBy}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="location-select-label">Location</InputLabel>
                                <Select
                                    labelId="location-select-label"
                                    id="location-select"
                                    label="Location"
                                    name="locationId"
                                    value={formik.values.locationId}
                                    onChange={formik.handleChange}
                                >
                                    {location?.map((location, index) => {
                                        return (
                                            <MenuItem key={index} value={location.id}>
                                                <Stack direction="column">
                                                    <Typography>{location.city}</Typography>
                                                </Stack>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="note"
                                label="Note"
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                fullWidth
                                multiline
                                rows={3}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <LoadingButton variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </LoadingButton>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default SalesRecodsForm;
