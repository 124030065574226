import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useAmountTransactionService from 'services/useAmountTransactionService';
import useSalariesService from 'services/useSalariesService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import { daysInMonth } from 'utils/DateOperations';

const BM6Card = (props) => {
    const { employee, bm6SalarySlabDetails, bm6FiledExpensesDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(daysInMonth(new Date().getMonth(), new Date().getFullYear()));
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(true);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();
    const [bm6SalaryDetails, SetBM6SalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        incentive: 0,
        // adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedBM6SalarySlab, setAppliedBM6SalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        incentive: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    //calculated values
    const handleCalculate = () => {
        const perDaySalary = appliedBM6SalarySlab.fixedSalary / daysInMonth(month, year);
        // Calculate the basic salary based on the number of working days
        const basicSalary = perDaySalary * workDays;
        let calcAmount = appliedBM6SalarySlab.amount * unitsSold;
        let calcIncentive = appliedBM6SalarySlab.incentive;
        let calcSubTotal = basicSalary + calcAmount + calcIncentive;
        let calcDeduction = pendingAmountDetails?.amount || 0;
        let calcNetAmount = calcSubTotal - calcDeduction;

        SetBM6SalaryDetails((prev) => ({
            ...prev,
            fixedSalary: basicSalary,
            units: unitsSold,
            amount: calcAmount,
            incentive: calcIncentive,
            // adjustment: adjustment,
            subTotal: calcSubTotal,
            deduction: calcDeduction,
            netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(bm6FiledExpensesDetails);
        let selectedData = bm6FiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: bm6SalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                amount: selectedData[0].amount,
                incentive: selectedData[0].incentive
            };
            setAppliedBM6SalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                console.log('res', response.data);
                setUnitsSold(response.data.unit);
            }
        });
    };

    const bm6SaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            unitSold: unitsSold,
            workDays: workDays,
            //adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: bm6SalaryDetails.fixedSalary,
            fieldExpences: bm6SalaryDetails.fieldExpenses,
            incentive: bm6SalaryDetails.incentive,
            subTotal: bm6SalaryDetails.subTotal,
            deduction: bm6SalaryDetails.deduction,
            netSalary: bm6SalaryDetails.netAmount,
            remark: ''
        };
        //console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setSaveGCSalary(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleCalculate();
    }, [petrolRate, workDays, unitsSold, bm6SalarySlabDetails, bm6FiledExpensesDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (bm6SalarySlabDetails && bm6FiledExpensesDetails) {
            handleSetSalarySlabData();
        }
    }, [bm6SalarySlabDetails, bm6FiledExpensesDetails, unitsSold]);

    console.log('Units', unitsSold);
    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                {editMode && (
                    <Button
                        size="small"
                        onClick={() => {
                            handleGetPendingAmount();
                            handelGetSalesData();
                            handleCalculate();
                        }}
                    >
                        <RefreshIcon fontSize="small" />
                    </Button>
                )}
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />

                    <TextField
                        label="Petrol Rate"
                        size="small"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" spacing={3}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Units Sold
                        </Typography>
                        <Typography>{unitsSold}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Work Days
                        </Typography>
                        <Typography>{workDays}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Petrol Rate
                        </Typography>
                        <Typography>₹ {petrolRate}</Typography>
                    </Stack>
                </Stack>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${bm6SalaryDetails.fixedSalary.toFixed(2)} (From Table)`}
                    value={bm6SalaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedBM6SalarySlab.amount} (From Table)`}
                    value={bm6SalaryDetails.amount}
                />
                <SalaryDetailComponent
                    title="Incentive"
                    description={`₹ ${appliedBM6SalarySlab.incentive} (From Table)`}
                    value={bm6SalaryDetails.incentive}
                />

                <Divider />
                <SalaryDetailComponent title="subTotal" value={bm6SalaryDetails.subTotal} />
                <Divider />
                <SalaryDetailComponent
                    title="Deduction"
                    description={` ₹ ${bm6SalaryDetails.deduction}`}
                    value={bm6SalaryDetails.deduction}
                />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={bm6SalaryDetails.netAmount} />
                <Divider />
            </Stack>

            {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={bm6SalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetBM6SalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h6">Remarks</Typography>
                    <Typography>{bm6SalaryDetails.remarks == '' ? '---' : bm6SalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? ( */}
            <>
                <Button
                    variant="outlined"
                    onClick={() => {
                        handleCalculate();
                        console.log('calc', handleCalculate());
                    }}
                >
                    Calculate
                </Button>
                <LoadingButton
                    loading={submitting}
                    variant="contained"
                    onClick={() => {
                        // handleCalculate();
                        // setEditMode(false);
                        bm6SaveSalary();
                    }}
                    sx={{ marginLeft: 2 }}
                >
                    Save
                </LoadingButton>
            </>
            {/* ) : (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        setEditMode(true);
                    }}
                >
                    Edit
                </Button>
            )} */}
        </Card>
    );
};

export default BM6Card;
