import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icon imports
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryReadOnly from '../SalaryReadOnly';

const RODDSMSalarySheet = () => {
    const { employee, ddsmSalarySlabDetails, ddsmFiledExpensesDetails, onRefresh, month, year, salaryData } = props;
    const theme = useTheme();
    //const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    // const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [ddsmSalaryDetails, SetDDSMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        incentive: 0,
        //adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedDDSMSalarySlab, setAppliedDDSMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        incentive: 0
    });

    //Methods

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {/* {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName} */}
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {ddsmSalaryDetails.adjustment}</Typography>
                </Stack> */}
                            </Stack>

                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    //description={`₹ ${appliedDDSMSalarySlab.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    //description={`${unitsSold} Units x ₹ ${appliedDDSMSalarySlab.amount} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="Incentive"
                                    //description={` ₹ ${appliedDDSMSalarySlab.incentive} (From Table)`}
                                    value={row.incentive}
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Sub Total"
                                    value={
                                        // appliedDDSMSalarySlab.fixedSalary + appliedDDSMSalarySlab.amount * unitsSold + appliedDDSMSalarySlab.incentive
                                        row.subTotal
                                    }
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${ddsmSalaryDetails.deduction}`}
                                    value={row.deduction}
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Net Salary"
                                    value={
                                        // appliedDDSMSalarySlab.fixedSalary +
                                        // appliedDDSMSalarySlab.amount * unitsSold +
                                        // appliedDDSMSalarySlab.incentive -
                                        //ddsmSalaryDetails.deduction
                                        row.netSalary
                                    }
                                />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default RODDSMSalarySheet;
