import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
//MaterialUI Imports
import { Alert, Autocomplete, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useBM5SalarySlabs from 'services/useBM5SalarySlabsService';
import useBM5FieldExpenses from 'services/useBM5FieldExpensesService';

//project imports
//import BM5Card from './BM5Card';
import DDSMCard from './DDSMCard';
const DDSMSalarySheets = () => {
    const { selectedRole, selectedLocation, selectedMonth, selectedYear } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    //states

    const [employees, setEmployees] = useState();
    const [ddsmSalarySlabDetails, setDDSMSalarySlabDetails] = useState();
    const [ddsmFiledExpensesDetails, setDDSMFiledExpensesDetails] = useState();

    //services
    const { getEmployees } = useEmployeeService();
    const { getBM5SalarySlab } = useBM5SalarySlabs();
    const { getBM5FieldExpencesByBM5SalarySlabId } = useBM5FieldExpenses();

    //handlers
    const handleGetEmployees = () => {
        getEmployees('', selectedRole, selectedLocation, 0, 100000)
            .then((response) => {
                if (response.status == 200) {
                    setEmployees(response.data.users);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetSalarySlabs = () => {
        getDDSMSalarySlab()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ddsmSalarySlabs.length > 0) {
                        setDDSMSalarySlabDetails(response.data.ddsmSalarySlabs[0]);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetFieldExpenses = () => {
        getDDSmFieldExpencesByBM5SalarySlabId(ddsmSalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setDDSMFiledExpensesDetails(response.data.ddsmFieldExpences);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleGetEmployees();
        handleGetSalarySlabs();
    }, [selectedRole, selectedLocation]);

    useEffect(() => {
        if (ddsmSalarySlabDetails) {
            handleGetFieldExpenses();
        }
    }, [ddsmSalarySlabDetails]);

    return (
        <Box>
            <Grid container spacing={2}>
                {employees ? (
                    <>
                        {employees.map((employee, index) => {
                            return (
                                <Grid item xs={12} md={6} key={index}>
                                    <DDSMCard
                                        employee={employee}
                                        ddsmSalarySlabDetails={ddsmSalarySlabDetails}
                                        ddsmFiledExpensesDetails={ddsmFiledExpensesDetails}
                                        onRefresh={() => {
                                            handleGetSalarySlabs();
                                            handleGetFieldExpenses();
                                        }}
                                        location={selectedLocation}
                                        month={selectedMonth}
                                        year={selectedYear}
                                    />
                                </Grid>
                            );
                        })}
                    </>
                ) : null}
            </Grid>
        </Box>
    );
};

export default DDSMSalarySheets;
