import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const ROGCSalarySheet = (props) => {
    const {
        employee,
        gcSalarySlabDetails,
        gcFiledExpensesDetails,
        gcGroupIncentiveDetails,
        onRefresh,
        month,
        year,
        selectedRole,
        salaryData
    } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(30);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(90);
    const [editMode, setEditMode] = useState(false);
    const [salesUnits, setSalesUnits] = useState(0);
    const [saveGCSalary, setSaveGCSalary] = useState();
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [gcSalaryDetails, SetGCSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        //adjustment: 0,
        petrol: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedGCSalarySlab, setAppliedGCSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0
    });

    const [gcGroupIncentive, setGCGroupIncentive] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0
    });
    const [gcGroupIncentiveSalarySlabs, setGCGroupIncentiveSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0
    });
    const [units, setUnits] = useState(0);

    //Methods

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        DA Days
                                    </Typography>
                                    <Typography>{row.daDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                            </Stack>

                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    //description={`₹ ${appliedGCSalarySlab.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    // description={`${salesUnits} Units x ₹ ${appliedGCSalarySlab.fieldExpenses} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="Incentive"
                                    //description={` ₹ ${appliedGCSalarySlab.incentive} (From Table)`}
                                    value={row.incentive}
                                />
                                <SalaryReadOnly
                                    title="PRI"
                                    //description={`₹ ${appliedGCSalarySlab.pri} (From Table)`}
                                    value={row.pri}
                                />
                                <SalaryReadOnly
                                    title="D.A."
                                    //description={` ₹ ${appliedGCSalarySlab.da} (From Table)`}
                                    value={row.da}
                                />
                                <SalaryReadOnly
                                    title="T.A."
                                    //description={` ₹ ${appliedGCSalarySlab.ta} (From Table)`}
                                    value={row.ta}
                                />
                                <SalaryReadOnly
                                    title="Petrol"
                                    // description={
                                    //     employee.haveVehicle
                                    //         ? `(${salesUnits} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                                    //         : 'Not applicable'
                                    // }
                                    value={row.petrol}
                                />
                                <SalaryReadOnly
                                    title="Group Incentive"
                                    //description={`${unitsSold - salesUnits} Units x ₹ ${gcGroupIncentiveSalarySlabs.amount} `}
                                    value={row.groupIncentive}
                                />
                                <Divider />
                                <SalaryReadOnly title="Sub Total" value={row.subTotal} />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${gcSalaryDetails.deduction}`}
                                    value={row.deduction}
                                />
                                <Divider />
                                <SalaryReadOnly title="Net Salary" value={row.netSalary} />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ROGCSalarySheet;
