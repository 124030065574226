import React, { useState, useEffect } from 'react';

//MaterialUI Imports
import { IconButton, Snackbar, Stack, TextField, Tooltip, Typography, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SalaryDetailComponent = (props) => {
    const { title, description, value } = props;
    const theme = useTheme();

    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="caption">{description}</Typography>
            </Stack>
            <Typography variant="h5">
                ₹{' '}
                {Number(value).toLocaleString('en-IN', {
                    maximumFractionDigits: 2
                })}
            </Typography>
        </Stack>
    );
};

export default SalaryDetailComponent;
