import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import SalaryReadOnly from '../SalaryReadOnly';
import useAmountTransactionService from 'services/useAmountTransactionService';

const RODBMSalarySheets = (props) => {
    const { employee, dbmSalarySlabDetails, dbmFiledExpensesDetails, onRefresh, month, year, salaryData } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [dbmSalaryDetails, SetDBMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        incentive: 0,
        //adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedDBMSalarySlab, setAppliedDBMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        incentive: 0
    });

    //Methods

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {/* {employee?.salutationDisplay} {employee?.firstName} {employee?.middleName} {employee?.lastName} */}
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row?.mobileNumber}
                                    </Typography>
                                    {/* <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    {employee?.role == 1 && employee?.haveVehicle && (
                                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                                    )} */}
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                                {/* <Stack direction="column" spacing={1}>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        Adjustments
                    </Typography>
                    <Typography>₹ {dbmSalaryDetails.adjustment}</Typography>
                </Stack> */}
                            </Stack>
                            {/* )} */}
                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    //description={`₹ ${appliedDBMSalarySlab.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    //description={`${unitsSold} Units x ₹ ${appliedDBMSalarySlab.fieldExpenses} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="Incentive"
                                    //description={` ₹ ${appliedDBMSalarySlab.incentive} (From Table)`}
                                    value={row.incentive}
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Sub Total"
                                    value={
                                        // appliedDBMSalarySlab.fixedSalary +
                                        // appliedDBMSalarySlab.fieldExpenses * unitsSold +
                                        // appliedDBMSalarySlab.incentive
                                        row.subTotal
                                    }
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${dbmSalaryDetails.deduction}`}
                                    value={row.deduction}
                                />
                                <Divider />
                                <SalaryReadOnly
                                    title="Net Salary"
                                    value={
                                        // appliedDBMSalarySlab.fixedSalary +
                                        // appliedDBMSalarySlab.fieldExpenses * unitsSold +
                                        // appliedDBMSalarySlab.incentive -
                                        // dbmSalaryDetails.deduction
                                        row.netSalary
                                    }
                                />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default RODBMSalarySheets;
