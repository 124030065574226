import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import { Divider, Stack, Typography, Card, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
//import useAmountTransactionService from 'services/useAmountTransactionService';

const ROABMSalarySheet = (props) => {
    const {
        employee,
        abmSalarySlabDetails,
        abmFiledExpensesDetails,
        abmDailyAllowanceDetails,
        abmPRIsDetails,
        onRefresh,
        month,
        year,
        salaryData
    } = props;
    const theme = useTheme();
    //const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    // const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [abmSalaryDetails, SetABMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0,
        //campToCamp: 0,
        //adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedABMSalarySlab, setAppliedABMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        petrol: 0,
        incentive: 0
    });

    const [abmDailyAllowance, setABMDailyAllowance] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0,
        campToCamp: 0
    });
    const [appliedABMDAsSalarySlab, setAppliedABMDAsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0,
        campToCamp: 0
    });

    const [abmPRIsSalaryDetails, setABMPRIsSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        pri: 0
    });
    const [appliedABMPRIsSalarySlab, setAppliedABMPRIsSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        pri: 0
    });

    //Methods

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        DA Days
                                    </Typography>
                                    <Typography>{row.daDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                            </Stack>

                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    //description={`₹ ${appliedABMSalarySlab.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    //description={`${unitsSold} Units x ₹ ${appliedABMSalarySlab.fieldExpenses} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="Petrol"
                                    //description={`₹ ${appliedABMSalarySlab.petrol} (From Table)`}
                                    value={row.petrol}
                                />
                                <SalaryReadOnly
                                    title="Incentive"
                                    //description={`₹ ${appliedABMSalarySlab.incentive} (From Table)`}
                                    value={row.incentive}
                                />
                                <SalaryReadOnly
                                    title="DAs"
                                    //description={`₹ ${appliedABMDAsSalarySlab.amount} (From Table)`}
                                    value={row.da}
                                />
                                <SalaryReadOnly
                                    title="Camp To Camp"
                                    //description={`₹ ${appliedABMDAsSalarySlab.campToCamp} (From Table)`}
                                    value={row.campToCamp}
                                />
                                <SalaryReadOnly
                                    title="PRI"
                                    //description={`₹ ${appliedABMPRIsSalarySlab.pri} (From Table)`}
                                    value={row.pri}
                                />
                                <Divider />

                                <SalaryReadOnly title="Sub Total" value={row.subTotal} />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${abmSalaryDetails.deduction}`}
                                    value={row.deduction}
                                />

                                <Divider />
                                <SalaryReadOnly title="Net Salary" value={row.netSalary} />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ROABMSalarySheet;
