import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import { Alert, Autocomplete, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useBM5SalarySlabs from 'services/useBM5SalarySlabsService';
import useBM5FieldExpenses from 'services/useBM5FieldExpensesService';
//project imports
//import BM5Card from './BM5Card';
import ROBM5SalarySheet from './ROBM5SalarySheet';
import useSalariesService from 'services/useSalariesService';

const ROBM5 = (props) => {
    const { selectedRole, selectedLocation, selectedMonth, selectedYear } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    //states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [bm5SalarySlabDetails, setBM5SalarySlabDetails] = useState();
    const [bm5FiledExpensesDetails, setBM5FiledExpensesDetails] = useState();
    const [salaryReadOnly, setSalaryReadOnly] = useState();
    //services
    const { getEmployees } = useEmployeeService();
    const { getBM5SalarySlab } = useBM5SalarySlabs();
    const { getBM5FieldExpencesByBM5SalarySlabId } = useBM5FieldExpenses();
    const { getAllSalaries } = useSalariesService();
    //handlers

    const handleGetEmployees = () => {
        getEmployees('', selectedRole, selectedLocation, 0, 100000)
            .then((response) => {
                if (response.status == 200) {
                    setEmployees(response.data.users);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalaryReadOnly = () => {
        getAllSalaries(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log('response', selectedRole, selectedLocation, selectedMonth, selectedYear);
                if (response.status == 200) {
                    setSalaryReadOnly(response.data.salaries);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalarySlabs = () => {
        getBM5SalarySlab()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.bM5SalarySlabs.length > 0) {
                        setBM5SalarySlabDetails(response.data.bM5SalarySlabs[0]);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetFieldExpenses = () => {
        getBM5FieldExpencesByBM5SalarySlabId(bm5SalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setBM5FiledExpensesDetails(response.data.bM5FieldExpences);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleGetEmployees();
        handleGetSalarySlabs();
    }, [selectedRole, selectedLocation]);

    useEffect(() => {
        if (bm5SalarySlabDetails) {
            handleGetFieldExpenses();
        }
    }, [bm5SalarySlabDetails]);
    useEffect(() => {
        handleGetSalaryReadOnly();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);
    return (
        <Box>
            <Grid container spacing={2}>
                {employees ? (
                    <>
                        {/* {employees.map((employee, index) => {
                            return ( */}
                        <Grid item xs={12} md={12}>
                            <ROBM5SalarySheet
                                employee={employees}
                                bm5SalarySlabDetails={bm5SalarySlabDetails}
                                bm5FiledExpensesDetails={bm5FiledExpensesDetails}
                                salaryData={salaryReadOnly}
                                onRefresh={() => {
                                    handleGetSalarySlabs();
                                    handleGetFieldExpenses();
                                }}
                                location={selectedLocation}
                                month={selectedMonth}
                                year={selectedYear}
                            />
                        </Grid>
                        {/* );
                        })} */}
                    </>
                ) : null}
            </Grid>
        </Box>
    );
};

export default ROBM5;
