import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    CircularProgress,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';

//icons
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//project imports
import { locations, roles } from 'store/constant';
import NoResults from 'assets/images/noresults.png';
import useSalariesService from 'services/useSalariesService';
import useEmployeeService from 'services/useEmployeeService';
import useReportService from 'services/useReportService';
import useLocationService from 'services/useLocationService';

const RevenueReport = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const { getRevenueTotalReport } = useReportService();
    const { getLocationList } = useLocationService();
    const { getEmployees } = useEmployeeService();
    //const { month, year } = props;
    const pageSize = 50;

    //States
    const [employeeRevenueDetails, setEmployeeRevenueDetails] = useState([]);
    //const date = new Date();
    const [selectedRole, setSelectedRole] = useState(-1);
    const [selectedLocation, setSelectedLocation] = useState(-1);
    const [selectedFromDate, setSelectedFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedToDate, setSelectedToDate] = useState(new Date().toISOString().split('T')[0]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [selectedEmployeeSalaryRecord, setSelectedEmployeeSalaryRecord] = useState();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const [isError, setIsError] = useState();
    const [location, setLocation] = useState();
    //Methods

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };
    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        handleGetLocation();
    }, []);
    const getEmployeeRevenueList = () => {
        //setEmployeeSalaryDetails(undefined);
        getRevenueTotalReport(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log(response.data);
                setEmployeeRevenueDetails(response.data);
            })
            .catch((error) => setIsError(error.message));
    };

    //useEffects
    useEffect(() => {
        getEmployeeRevenueList();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<AssessmentIcon color="primary" />}
                    title="Revenue Report"
                    sx={{ padding: 0 }}
                    action={
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate('/sales');
                            }}
                            sx={{ marginRight: 1 }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    }
                />
                <Divider sx={{ marginY: 3 }} />
                <Grid container direction="row" spacing={2} ustifyContent="space-between" alignItems="center" sx={{ marginY: 0.5 }}>
                    <Grid item sm={4} xs={12}>
                        <Typography sx={{ mb: 0.5, color: theme.palette.grey[600] }}>Select Role</Typography>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            name="role"
                            //label="Role"
                            size="small"
                            fullWidth
                            value={selectedRole}
                            onChange={(e) => {
                                setSelectedRole(e.target.value);
                            }}
                        >
                            <MenuItem key={-1} value={-1}>
                                All
                            </MenuItem>
                            {roles?.map((role, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        <Typography>{role}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Typography sx={{ mb: 0.5, color: theme.palette.grey[600] }}>Select Location</Typography>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            name="location"
                            size="small"
                            fullWidth
                            //sx={{ width: 150 }}
                            value={selectedLocation}
                            onChange={(e) => {
                                setSelectedLocation(e.target.value);
                            }}
                        >
                            <MenuItem key={-1} value={-1}>
                                All
                            </MenuItem>
                            {location?.map((location, index) => {
                                return (
                                    <MenuItem key={index} value={location.id}>
                                        <Typography>{location.city}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {/* </Stack> */}
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Typography sx={{ mb: 0.5, color: theme.palette.grey[600] }}>Select Month</Typography>
                        <TextField
                            size="small"
                            type="number"
                            fullWidth
                            //sx={{ width: 100 }}
                            value={selectedMonth}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Typography sx={{ mb: 0.5, color: theme.palette.grey[600] }}>Select Year</Typography>
                        <TextField
                            size="small"
                            type="number"
                            fullWidth
                            //sx={{ width: 100 }}
                            value={selectedYear}
                            onChange={(e) => {
                                setSelectedYear(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 3 }} />
                {employeeRevenueDetails != undefined || employeeRevenueDetails != null ? (
                    <>
                        <Grid container direction="row" spacing={2}>
                            <Grid item sm={3} xs={12} align="left">
                                <Card variant="outlined" sx={{ backgroundColor: theme.palette.orange.light, borderRadius: 2, padding: 2 }}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                            Total Units Sold:
                                        </Typography>
                                        <Typography display="inline" variant="h3">
                                            {employeeRevenueDetails.totalSaleUnit?.toFixed(0)}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item sm={3} xs={12} align="left">
                                <Card variant="outlined" sx={{ backgroundColor: theme.palette.success.light, borderRadius: 2, padding: 2 }}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                            Total Sales Amount:
                                        </Typography>
                                        <Typography display="inline" variant="h3">
                                            ₹ {employeeRevenueDetails.totalAmount?.toFixed(2)}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Grid>
                            {/* <Grid item sm={3} xs={12} align="left">
                                <Card variant="outlined" sx={{ backgroundColor: theme.palette.success.light, borderRadius: 2, padding: 2 }}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                            Total Sales Amount:
                                        </Typography>
                                        <Typography display="inline" variant="h3">
                                            ₹ {employeeRevenueDetails.totalAmount?.toFixed(2)}
                                        </Typography>
                                    </Stack>
                                </Card>
                            </Grid> */}
                        </Grid>
                    </>
                ) : null}

                <Divider sx={{ marginY: 3 }} />
                {employeeRevenueDetails != undefined || employeeRevenueDetails != null ? (
                    <>
                        {employeeRevenueDetails.length === 0 ? (
                            <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                <img src={NoResults} alt="No Result" width="150px" />
                                <Typography variant="h4" fontWeight={100}>
                                    There are no employee revenue record found
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableCell sx={{ width: 30 }}>
                                                    <Typography variant="h5">Sr.No.</Typography>{' '}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h5">Employee Name</Typography>{' '}
                                                </TableCell>
                                                <TableCell sx={{ width: 180 }}>
                                                    <Typography variant="h5">Role</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 100 }}>
                                                    <Typography variant="h5"> Unit Sold</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5">Team Unit Sold</Typography>
                                                </TableCell>
                                                <TableCell align="right" sx={{ width: 120 }}>
                                                    <Typography variant="h5">Sale Amount</Typography>
                                                </TableCell>
                                                <TableCell align="right" sx={{ width: 120 }}>
                                                    <Typography variant="h5">Team Sale Amount</Typography>
                                                </TableCell>
                                                <TableCell align="right" sx={{ width: 100 }}>
                                                    <Typography variant="h5">Total Unit</Typography>
                                                </TableCell>
                                                <TableCell align="right" sx={{ width: 100 }}>
                                                    <Typography variant="h5">Total Sum</Typography>
                                                </TableCell>
                                                {/* <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5" align="right">
                                                        Total Sale Amount
                                                    </Typography>
                                                </TableCell> */}
                                                {/* <TableCell sx={{ width: 190 }}>
                                                <Typography variant="h5">Recorded By</Typography>
                                            </TableCell> */}

                                                <TableCell sx={{ width: 120 }}></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {employeeRevenueDetails.revenueReports?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {'#'}
                                                        </TableCell>
                                                        <TableCell align="left">{row.userName}</TableCell>
                                                        <TableCell align="left">{row.roleDisplay}</TableCell>
                                                        <TableCell align="left">{row.totalSaleUnit}</TableCell>
                                                        <TableCell align="left">{row.totalTeamUnit}</TableCell>

                                                        <TableCell align="right">{row.totalAmount?.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{row.totalTeamAmount.toFixed(2)}</TableCell>

                                                        <TableCell align="right">{row.totalSum.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{row.totalUnit.toFixed(2)}</TableCell>
                                                        {/* <TableCell align="right">
                                                            {employeeRevenueDetails.totalAmount?.toFixed(2)}
                                                        </TableCell> */}
                                                        <TableCell align="right">
                                                            <Tooltip title="Sales details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        const state = {
                                                                            userId: row.userId,
                                                                            month: selectedMonth,
                                                                            year: selectedYear
                                                                        };
                                                                        navigate('/revenuereportdetails', { state });
                                                                    }}
                                                                >
                                                                    <ArticleIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/*<Tooltip title="Edit Sales Record details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedSalesRecord(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                            <Tooltip title="Delete employee salary record">
                                                                <IconButton
                                                                    color="error"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedEmployeeSalaryRecord(row);
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {employeeRevenueDetails.revenueReports?.map((row, index) => (
                                    <Grid key={index} item xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                                        <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="h5">Employee Name:</Typography>
                                                <Typography>{row.userName}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Employee Role:</Typography>
                                                <Typography> {row.roleDisplay}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Employee Sale Unit:</Typography>
                                                <Typography>{row.totalSaleUnit}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Total Amount:</Typography>
                                                <Typography>{row.totalAmount}</Typography>
                                            </Stack>

                                            <Divider sx={{ mt: 1 }} />
                                            <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                <Tooltip title="Sales details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            const state = {
                                                                userId: row.userId,
                                                                fromDate: selectedFromDate,
                                                                toDate: selectedToDate
                                                            };
                                                            navigate('/revenuereportdetails', { state });
                                                        }}
                                                    >
                                                        <ArticleIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                {/*<Tooltip title="Edit Sales Record details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedSalesRecord(row);
                                                            setOpenForm(true);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>{' '}
                                                */}
                                                <Tooltip title="Delete employee salary record">
                                                    <IconButton
                                                        color="error"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedEmployeeSalaryRecord(row);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            Getting Employee Revenue List...
                        </Typography>
                        <CircularProgress />
                    </Stack>
                )}
            </MainCard>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete salary Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this salary details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> Salary deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default RevenueReport;
