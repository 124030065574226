import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionsIcon from '@mui/icons-material/Functions';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useDBMSalarySlabsService from 'services/useDBMSalarySlabsService';
import useDBMFieldExpensesService from 'services/useDBMFieldExpensesService';

//project imports
//import DBMCard from './DBMCard';
import RODBMSalarySheets from './RODBMSalarySheets';
import useSalariesService from 'services/useSalariesService';

const RODBM = (props) => {
    const { selectedRole, selectedLocation, selectedMonth, selectedYear } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    //states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [dbmSalarySlabDetails, setDBMSalarySlabDetails] = useState();
    const [dbmFiledExpensesDetails, setDBMFiledExpensesDetails] = useState();
    const [salaryReadOnly, setSalaryReadOnly] = useState();
    //services
    const { getEmployees } = useEmployeeService();
    const { getDBMSalarySlab } = useDBMSalarySlabsService();
    const { getDBMFieldExpenses } = useDBMFieldExpensesService();
    const { getAllSalaries } = useSalariesService();
    //handlers
    const handleGetEmployees = () => {
        getEmployees('', selectedRole, selectedLocation, 0, 100000)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    setEmployees(response.data.users);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalaryReadOnly = () => {
        getAllSalaries(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log('response', selectedRole, selectedLocation, selectedMonth, selectedYear);
                if (response.status == 200) {
                    setSalaryReadOnly(response.data.salaries);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalarySlabs = () => {
        getDBMSalarySlab()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.list.length > 0) {
                        setDBMSalarySlabDetails(response.data.list[0]);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetFieldExpenses = () => {
        getDBMFieldExpenses(dbmSalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setDBMFiledExpensesDetails(response.data.list);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleGetEmployees();
        handleGetSalarySlabs();
    }, [selectedRole, selectedLocation]);

    useEffect(() => {
        if (dbmSalarySlabDetails) {
            handleGetFieldExpenses();
        }
    }, [dbmSalarySlabDetails]);
    useEffect(() => {
        handleGetSalaryReadOnly();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);
    return (
        <Box>
            <Grid container spacing={2}>
                {employees != undefined || employees != null ? (
                    <>
                        {/* {employees?.map((employee, index) => {
                            return ( */}
                        <Grid item xs={12} md={12}>
                            <RODBMSalarySheets
                                employee={employees}
                                dbmSalarySlabDetails={dbmSalarySlabDetails}
                                dbmFiledExpensesDetails={dbmFiledExpensesDetails}
                                salaryData={salaryReadOnly}
                                onRefresh={() => {
                                    handleGetSalarySlabs();

                                    handleGetFieldExpenses();
                                }}
                                location={selectedLocation}
                                month={selectedMonth}
                                year={selectedYear}
                            />
                        </Grid>
                        {/* );
                        })} */}
                    </>
                ) : null}
            </Grid>
        </Box>
    );
};

export default RODBM;
