import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import SalaryReadOnly from '../SalaryReadOnly';
//import useAmountTransactionService from 'services/useAmountTransactionService';

//const GCCard = (props) => {

const ROTMSalarySheet = (props) => {
    const {
        employee,
        tmSalarySlabDetails,
        tmFiledExpensesDetails,
        tmDailyAllowanceDetails,
        tmPRIsDetails,
        onRefresh,
        month,
        year,
        salaryData
    } = props;
    const theme = useTheme();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(26);
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(false);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        DA Days
                                    </Typography>
                                    <Typography>{row.daDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                            </Stack>

                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    //description={`₹ ${appliedTMSalarySlab.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    // description={`${unitsSold} Units x ₹ ${appliedTMSalarySlab.fieldExpenses} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="Petrol"
                                    //description={`₹ ${appliedTMSalarySlab.petrol} (From Table)`}
                                    value={row.petrol}
                                />
                                <SalaryReadOnly
                                    title="Incentive"
                                    //description={`₹ ${appliedTMSalarySlab.incentive} (From Table)`}
                                    value={row.incentive}
                                />
                                <SalaryReadOnly
                                    title="DAs"
                                    //description={`₹ ${tmDAsSalarySlabs.amount} (From Table)`}
                                    value={row.da}
                                />
                                <SalaryReadOnly
                                    title="Camp To Camp"
                                    //description={`₹ ${tmDAsSalarySlabs.campToCamp} (From Table)`}
                                    value={row.campToCamp}
                                />
                                <SalaryReadOnly
                                    title="PRI"
                                    //description={` ${tmPRIsSalarySlabs.pri} (From Table)`}
                                    value={row.pri}
                                />

                                <Divider />
                                <SalaryReadOnly title="Sub Total" value={row.subTotal} />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${tmSalaryDetails.deduction}`}
                                    value={row.deduction}
                                />
                                <Divider />
                                <SalaryReadOnly title="Net Salary" value={row.netSalary} />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ROTMSalarySheet;
