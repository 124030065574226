import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useReportService = () => {
    const getRevenueTotalReport = async (role, location, month, year) => {
        try {
            const response = await axiosAuth.get(
                `/api/Reports/revenue-total-report?role=${role}&location=${location}&month=${month}&year=${year}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getRevenueDetailsReport = async (userId, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/revenue-Detail-report?userId=${userId}&month=${month}&year=${year}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getRevenueTotalReport,
        getRevenueDetailsReport
    };
};

export default useReportService;
