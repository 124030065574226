import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';

//icon imports

import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useSalariesService from 'services/useSalariesService';
import useAmountTransactionService from 'services/useAmountTransactionService';
import { daysInMonth } from 'utils/DateOperations';

const GLCard = (props) => {
    const { employee, glSalarySlabDetails, glFiledExpensesDetails, glGroupIncentiveDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states

    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(daysInMonth(new Date().getMonth(), new Date().getFullYear()));
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(90);
    const [editMode, setEditMode] = useState(true);
    const [pendingAmountDetails, setPendingAmountDetails] = useState();
    const [glSalaryDetails, SetGLSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        //adjustment: 0,
        petrol: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedGLSalarySlab, setAppliedGLSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0
    });

    const [glGroupIncentiveSalary, setGLGroupIncentiveSalary] = useState({
        fixedSalary: 0,
        units: 0,
        amount: 0
    });
    const [glGroupIncentiveSalarySlabs, setGLGroupIncentiveSalarySlabs] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        amount: 0
    });
    const [glSalesUnit, setGLSalesUnit] = useState(0);
    const [totalUnits, setTotalUnits] = useState(0);
    const [daDays, setDaDays] = useState(workDays);
    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();
    //handlers
    const handleCalculate = () => {
        const perDaySalary = appliedGLSalarySlab.fixedSalary / daysInMonth(month, year);
        // Calculate the basic salary based on the number of working days
        const basicSalary = perDaySalary * workDays;
        //calculated values
        let workDaysData = workDays > 4 ? workDays - 4 : 0;
        let calcFieldExpenses = appliedGLSalarySlab.fieldExpenses * unitsSold;
        let calcIncentive = appliedGLSalarySlab.incentive;
        let calcPRI = appliedGLSalarySlab.pri;

        let calcDA = appliedGLSalarySlab.da * daDays;
        let calcTA = appliedGLSalarySlab.ta * daDays;
        let calcPetrol = 0;
        if (employee.haveVehicle && unitsSold > 25) {
            let liters = (unitsSold / 2) * 1.5;
            calcPetrol = (liters > 75 ? 75 : liters) * petrolRate;
        } else {
            calcPetrol = 0;
        }

        const totalUnits = unitsSold - glSalesUnit;
        setTotalUnits(totalUnits);

        let calcAmount = glGroupIncentiveSalarySlabs.amount * totalUnits;
        let calcSubTotal = basicSalary + calcFieldExpenses + calcPRI + calcDA + calcTA + calcPetrol + calcAmount + calcIncentive;
        let calcDeduction = pendingAmountDetails?.amount || 0;
        let calcNetAmount = calcSubTotal - calcDeduction;

        SetGLSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: basicSalary,
            units: glSalesUnit,
            fieldExpenses: calcFieldExpenses,
            pri: calcPRI,
            da: calcDA,
            ta: calcTA,
            petrol: calcPetrol,
            incentive: calcIncentive,
            // adjustment: adjustment,
            subTotal: calcSubTotal,
            deduction: calcDeduction,
            netAmount: calcNetAmount
        }));
        setGLGroupIncentiveSalary((prev) => ({
            ...prev,
            fixedSalary: glGroupIncentiveSalarySlabs.fixedSalary,
            units: totalUnits,
            amount: calcAmount
            // netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        let selectedData = glFiledExpensesDetails?.filter((item) => {
            return glSalesUnit >= item.unitMin && glSalesUnit <= item.unitMax;
        });
        let glGroupIncentiveData = glGroupIncentiveDetails?.filter((item) => {
            return totalUnits >= item.unitMin && totalUnits <= item.unitMax;
        });
        // //console.log(data);
        if (glGroupIncentiveData.length > 0) {
            let data = {
                fixedSalary: glSalarySlabDetails.fixedSalary,
                minUnit: glGroupIncentiveData[0].unitMin,
                maxUnit: glGroupIncentiveData[0].unitMax,
                amount: glGroupIncentiveData[0].amount
            };
            setGLGroupIncentiveSalarySlabs(data);
            handleCalculate();
        }
        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: glSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                pri: selectedData[0].pri,
                da: selectedData[0].da,
                ta: selectedData[0].ta,
                incentive: selectedData[0].incentive
            };
            setAppliedGLSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
                console.log(response.data.unit);
            }
        });
    };

    const handelGetSalesMonthlyData = () => {
        getUnitsSold(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setGLSalesUnit(response.data);
                console.log(response.data);
            }
        });
    };

    const glSaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            unitSold: glSalesUnit,
            workDays: workDays,
            daDays: daDays,
            //adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: glSalaryDetails.fixedSalary,
            fieldExpences: glSalaryDetails.fieldExpenses,
            incentive: glSalaryDetails.incentive,
            pri: glSalaryDetails.pri,
            da: glSalaryDetails.da,
            ta: glSalaryDetails.ta,
            petrol: glSalaryDetails.petrol,
            groupIncentive: glGroupIncentiveSalary.amount,
            subTotal: glSalaryDetails.subTotal,
            deduction: glSalaryDetails.deduction,
            netSalary: glSalaryDetails.netAmount,
            remark: ''
        };
        console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
    }, [
        petrolRate,
        workDays,
        totalUnits,
        unitsSold,
        glSalarySlabDetails,
        glFiledExpensesDetails,
        glGroupIncentiveDetails,
        glSalesUnit,
        employee.id
    ]);

    useEffect(() => {
        //handelGetSalesData();
        handelGetSalesMonthlyData();
    }, [month, year, employee.id]);

    useEffect(() => {
        handelGetSalesData();
        //handelGetSalesMonthlyData();
    }, [month, year, employee.id]);
    useEffect(() => {
        if (glSalarySlabDetails && glFiledExpensesDetails && glGroupIncentiveDetails) {
            handleSetSalarySlabData();
        }
    }, [glSalarySlabDetails, glFiledExpensesDetails, glGroupIncentiveDetails, unitsSold, totalUnits, glSalesUnit, workDays]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                <Button
                    size="small"
                    onClick={() => {
                        handleGetPendingAmount();
                        handelGetSalesData();
                        handelGetSalesMonthlyData();
                        handleCalculate();
                    }}
                >
                    <RefreshIcon fontSize="small" />
                </Button>
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            <Stack direction="row" spacing={1}>
                <TextField label="Units Sold" type="number" fullWidth size="small" value={glSalesUnit} />
                <TextField
                    label="Work Days"
                    fullWidth
                    type="number"
                    size="small"
                    value={workDays}
                    onChange={(e) => {
                        setWorkDays(e.target.value);
                    }}
                />
                <TextField
                    label="DA Days"
                    fullWidth
                    type="number"
                    size="small"
                    value={daDays}
                    onChange={(e) => {
                        setDaDays(e.target.value);
                    }}
                />

                <TextField
                    label="Petrol Rate"
                    size="small"
                    type="number"
                    fullWidth
                    value={petrolRate}
                    onChange={(e) => {
                        setPetrolRate(e.target.value);
                    }}
                />
            </Stack>

            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${glSalaryDetails.fixedSalary.toFixed(2)} (From Table)`}
                    value={glSalaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${glSalesUnit} Units x ₹ ${appliedGLSalarySlab.fieldExpenses} (From Table)`}
                    value={glSalaryDetails.fieldExpenses}
                />
                <SalaryDetailComponent
                    title="Incentive"
                    description={` ₹ ${appliedGLSalarySlab.incentive} (From Table)`}
                    value={glSalaryDetails.incentive}
                />
                <SalaryDetailComponent title="PRI" description={`₹ ${appliedGLSalarySlab.pri} (From Table)`} value={glSalaryDetails.pri} />
                <SalaryDetailComponent
                    title="D.A."
                    description={`(${daDays} Days) x ₹ ${appliedGLSalarySlab.da} (From Table)`}
                    value={glSalaryDetails.da}
                />
                <SalaryDetailComponent
                    title="T.A."
                    description={`(${daDays} Days) x  ₹ ${appliedGLSalarySlab.ta} (From Table)`}
                    value={glSalaryDetails.ta}
                />
                <SalaryDetailComponent
                    title="Petrol"
                    description={
                        employee.haveVehicle
                            ? `(${glSalesUnit} Units / 2) x 1.5 Ltr x ₹ ${petrolRate} (Rate) - Max 75 Ltrs`
                            : 'Not applicable'
                    }
                    value={glSalaryDetails.petrol}
                />
                <SalaryDetailComponent
                    title="Group Incentive"
                    description={`${totalUnits} Units x ₹ ${glGroupIncentiveSalarySlabs.amount} (From Table) `}
                    value={glGroupIncentiveSalary.amount}
                />
                <Divider />
                <SalaryDetailComponent title="Sub Total" value={glSalaryDetails.subTotal} />
                <Divider />
                <SalaryDetailComponent
                    title="Deduction"
                    description={` ₹ ${glSalaryDetails.deduction}`}
                    value={glSalaryDetails.deduction}
                />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={glSalaryDetails.netAmount} />
                <Divider />
            </Stack>

            <TextField
                label="Remarks"
                fullWidth
                size="small"
                value={glSalaryDetails.remarks}
                sx={{ marginTop: 2 }}
                multiline
                onChange={(e) => {
                    SetGLSalaryDetails((prev) => ({
                        ...prev,
                        remarks: e.target.value
                    }));
                }}
            />

            <Divider sx={{ marginY: 2 }} />
            {/* {editMode ? (
                <> */}
            <Button
                variant="outlined"
                onClick={() => {
                    handleCalculate();
                }}
            >
                Calculate
            </Button>
            <LoadingButton
                loading={submitting}
                variant="contained"
                onClick={() => {
                    glSaveSalary();
                    setEditMode(false);
                }}
                sx={{ marginLeft: 2 }}
            >
                Save
            </LoadingButton>
        </Card>
    );
};

export default GLCard;
