import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    CircularProgress,
    Select,
    MenuItem,
    Tabs,
    Tab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';

//icons
import PaymentIcon from '@mui/icons-material/Payment';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

//project imports
import { locations, roles } from 'store/constant';
import NoResults from 'assets/images/noresults.png';
import useEmployeeService from 'services/useEmployeeService';
import AmountTransactionForm from './AmountTransactionForm';
import useAmountTransactionService from 'services/useAmountTransactionService';

const AmountPaid = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const { getEmployees } = useEmployeeService();
    const { getAmountTransactionFilter, deleteAmountTransaction } = useAmountTransactionService();
    //const { month, year } = props;
    const pageSize = 50;
    const { userId } = useSelector((state) => state.userReducer);

    //States

    const [senderPaidDetails, setSenderPaidDetails] = useState([]);
    const [employeeData, setEmployeeData] = useState();
    const [selectedAmountTransaction, setSelectedAmountTransaction] = useState();
    const [selectedSender, setSelectedSender] = useState();
    const [selectedReceiver, setSelectedReceiver] = useState(0);
    const [selectedFromDate, setSelectedFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedToDate, setSelectedToDate] = useState(new Date().toISOString().split('T')[0]);

    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [currentSkipValue, setCurrentSkipValue] = useState(0);
    const [isError, setIsError] = useState();
    const [tabValue, setTabValue] = useState();
    //Methods
    const handleRefreshClick = () => {
        getAmountPaidList();
    };
    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getAmountPaidList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const getEmployeeList = () => {
        getEmployees(0, -1, -1, currentSkipValue, pageSize)
            .then((response) => {
                console.log(response);

                setEmployeeData(response.data.users);
            })
            .catch((error) => setIsError(error.message));
    };

    const getAmountPaidList = () => {
        //setEmployeeSalaryDetails(undefined);
        getAmountTransactionFilter(userId, selectedReceiver, selectedFromDate, selectedToDate, -1)
            .then((response) => {
                //console.log(selectedReceiver, selectedFromDate);
                console.log(response);

                setSenderPaidDetails(response.data.amountTransactions);
            })
            .catch((error) => setIsError(error.message));
    };

    const handleDelete = () => {
        if (selectedTransaction.status == 1) {
            alert('Cannot delete accepted transaction!');
            setOpenDeleteDialog(false);
            return;
        }
        deleteAmountTransaction(selectedTransaction.id)
            .then((response) => {
                getAmountPaidList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    //useEffects
    useEffect(() => {
        getAmountPaidList();
    }, [selectedReceiver, selectedFromDate, selectedToDate]);
    useEffect(() => {
        getEmployeeList();
    }, []);
    //console.log(selectedReceiver);
    return (
        <>
            {/* <Divider sx={{ marginY: 3 }} /> */}

            <Typography variant="h4">Select parameters to view amount paid</Typography>
            <Grid container direction="row" spacing={2} alignItems="center" sx={{ marginY: 0.5 }}>
                {employeeData != undefined || employeeData != null ? (
                    <Grid item sm={5} xs={12}>
                        <Autocomplete
                            fullWidth
                            size="small"
                            disablePortal
                            id="employee-list-autocomplete"
                            getOptionLabel={(employeeData) =>
                                `${employeeData.firstName} ${employeeData.middleName} ${employeeData.lastName}`
                            }
                            options={employeeData}
                            isOptionEqualToValue={(option, value) => {
                                option.firstName === value.firstName,
                                    option.middleName === value.middleName,
                                    option.lastName === value.lastName;
                            }}
                            noOptionsText={'No Item Found'}
                            onChange={(event, value) => {
                                setSelectedReceiver(value.id);
                            }}
                            renderOption={(props, employeeData) => (
                                <Box component="li" {...props} key={employeeData.id}>
                                    {`${employeeData.firstName} ${employeeData.middleName} ${employeeData.lastName}`}
                                </Box>
                            )}
                            renderInput={(params) => <TextField label="Sent To" size="small" fullWidth {...params} />}
                        />
                    </Grid>
                ) : (
                    <Typography variant="h6">Getting List</Typography>
                )}
                <Grid item sm={2} xs={12}>
                    <TextField
                        type="date"
                        label="From"
                        size="small"
                        fullWidth
                        //sx={{ width: 150 }}
                        value={selectedFromDate}
                        onChange={(e) => {
                            setSelectedFromDate(e.target.value);
                        }}
                    />
                </Grid>

                <Grid item sm={2} xs={12}>
                    <TextField
                        label="To"
                        size="small"
                        type="date"
                        fullWidth
                        //sx={{ width: 150 }}
                        value={selectedToDate}
                        onChange={(e) => {
                            setSelectedToDate(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Button
                            //size="small"
                            variant="outlined"
                            onClick={() => {
                                handleRefreshClick();
                            }}
                        >
                            Refresh
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="secondary"
                            //startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedAmountTransaction({
                                    id: 0,
                                    senderId: 0,
                                    receiverId: 0,
                                    date: new Date().toISOString().split('T')[0],
                                    amount: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add New
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider sx={{ marginY: 3 }} />

            {senderPaidDetails != undefined || senderPaidDetails != null ? (
                <>
                    {senderPaidDetails.length === 0 ? (
                        <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                            <img src={NoResults} alt="No Result" width="150px" />
                            <Typography variant="h4" fontWeight={100}>
                                There are no amount paid record found
                            </Typography>
                        </Grid>
                    ) : (
                        <>
                            <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <TableContainer>
                                    <Table
                                        sx={{
                                            border: 'solid',
                                            borderWidth: 1,
                                            borderColor: 'divider'
                                        }}
                                    >
                                        <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                            <TableCell sx={{ width: 40 }}>
                                                <Typography variant="h5">#</Typography>{' '}
                                            </TableCell>
                                            <TableCell sx={{ width: 200 }}>
                                                <Typography variant="h5">Sent To</Typography>{' '}
                                            </TableCell>
                                            <TableCell sx={{ width: 120 }}>
                                                <Typography variant="h5">Date</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h5">Comments</Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: 120 }}>
                                                <Typography variant="h5" align="right">
                                                    Amount
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: 120 }}>
                                                <Typography variant="h5" align="right">
                                                    Status
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: 80 }}></TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {senderPaidDetails?.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="left">{row.receiverName}</TableCell>
                                                    <TableCell align="left">{new Date(row.date).toLocaleDateString('hi-IN')}</TableCell>
                                                    <TableCell align="left">{row.comments}</TableCell>
                                                    <TableCell align="right">{row.amount}</TableCell>
                                                    <TableCell align="right">{row.status == 0 ? 'Not Accepted' : 'Accepted'}</TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedTransaction(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            {senderPaidDetails?.map((row, index) => (
                                <Grid key={index} item xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                                    <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                        <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                            <Typography variant="h5">Sender Name:</Typography>
                                            <Typography>{row.senderName}</Typography>
                                        </Stack>
                                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                            <Typography variant="h5">Receiver Name:</Typography>
                                            <Typography> {row.receiverName}</Typography>
                                        </Stack>
                                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                            <Typography variant="h5">Date:</Typography>
                                            <Typography>{new Date(row.date).toLocaleDateString('hi-IN')}</Typography>
                                        </Stack>
                                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                            <Typography variant="h5">Amount:</Typography>
                                            <Typography>{row.amount}</Typography>
                                        </Stack>

                                        <Divider sx={{ mt: 1 }} />
                                        <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                            <Tooltip title="Sales details">
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        const state = {
                                                            userId: row.userId,
                                                            fromDate: selectedFromDate,
                                                            toDate: selectedToDate
                                                        };
                                                        navigate('/revenuereportdetails', { state });
                                                    }}
                                                >
                                                    <ArticleIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            {/*<Tooltip title="Edit Sales Record details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedSalesRecord(row);
                                                            setOpenForm(true);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>{' '}
                                                */}
                                            <Tooltip title="Delete employee salary record">
                                                <IconButton
                                                    color="error"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedTransaction(row);
                                                        setOpenDeleteDialog(true);
                                                    }}
                                                >
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Card>
                                </Grid>
                            ))}
                        </>
                    )}
                </>
            ) : (
                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                    <Typography variant="h4" textAlign="center">
                        Getting Amount Transaction List...
                    </Typography>
                    <CircularProgress />
                </Stack>
            )}
            {/* </MainCard> */}

            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <AmountTransactionForm
                        selectedReceiver={selectedReceiver}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        employeeData={employeeData}
                        selectedAmountTransaction={selectedAmountTransaction}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Transaction
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this amount transaction?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> Amount transaction deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default AmountPaid;
